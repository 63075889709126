import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

// For more info on the @pwabuilder/pwainstall component click here https://github.com/pwa-builder/pwa-install
import '@pwabuilder/pwainstall';

@customElement('app-home')
export class AppHome extends LitElement {

  constructor() {
    super();
  }

  render() {

    return html`
    <div width="100vw" height="100vh">
    <iframe src="https://apps.powerapps.com/play/0668d06d-cc01-415a-b84b-dca1a128ff0a?source=iframe"
    style="
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    z-index: 999999;
    height: 100%;
  "></iframe>
  </div>
    `;
  }
}
